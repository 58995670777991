.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  min-width: 1500px;
  max-width: 1700px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keep-button {
  margin-right: 25px;
  margin-left: 25px;
}
.restart-button {
  margin-right: 25px;
  margin-left: 25px;
}
.download-button {
  margin-right: 25px;
  margin-left: 25px;
}
.steps {
  margin-bottom: 50px;
}

.my-girl {
  position: relative;
  margin: 25px;
  border-radius: 12px;
  border-style: solid;
  border-width: 6px;
  padding: 4px;
  border-color: rgba(0, 0, 0, 0.3);
}

.sizigi {
  position: absolute;
  /* background-color: rgb(46, 91, 236) !important;
  background-image: unset !important;
  color: white !important;
  font-size: 2em; */
  bottom: 20px;
  left: 20px;
  width: 50px;
  cursor: pointer;
}

.my-girl-image {
  transition: filter 200ms ease-in
}

.my-girl-loading {
  filter: blur(10px);
}

.my-girl-loaded {
  filter: blur(0px);
}

.girl {
  cursor: pointer;
}

.your-waifu {
  position: absolute;
  top: -120px;
  left: -120px;
  width: 200px;
}

.loading-callout {
  width: 675px;
  height: 675px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.refresh-button {
  position: absolute;
  /* background-color: rgb(46, 91, 236) !important;
  background-image: unset !important;
  color: white !important;
  font-size: 2em; */
  bottom: 20px;
  right: 20px;
}

.cards-container {
  display: grid;
  grid-template-columns: 150px 150px 150px 150px;
  grid-gap: 25px;
}

.cross-fade-appear {
  opacity: 0;
  transform: scale(0.7);
}
.cross-fade-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}
.cross-fade-enter {
  opacity: 0;
  transform: scale(0.7);
}
.cross-fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}
.cross-fade-exit {
  opacity: 1;
  transform: scale(1);
}
.cross-fade-exit-active {
  opacity: 0;
  transform: scale(0.7);
  transition: all 300ms;
}

.stacked {
  display: grid;
}

.stacked-1,
.stacked-2 {
  grid-column: 1;
  grid-row: 1;
}
